nav {
  z-index: 100;
}

nav:after {
  content: "";
  clear: both;
  display: table;
}

nav .logo {
  float: left;
  line-height: 70px;
  margin-left: 60px;
}
nav ul {
  float: right;
  margin-right: 40px;
  list-style: none;
  position: relative;
}
nav ul li {
float: left;
  display: inline-block;
  background: #fff;
  margin: 0 5px;
}
nav ul li a {
  color: #000;
  line-height: 70px;
  text-decoration: none;
  font-size: 17px;
  padding: 8px 15px;
  text-transform: uppercase;
}
nav ul li a:hover,
.is-active {
  color: var(--primary);
  border-radius: 5px;
  box-shadow: 0 0 5px var(--primary), 0 0 10px var(--primary);
}
nav ul ul li a:hover {
  box-shadow: none;
}

nav ul ul li a {
  font-size: 15px;
  text-transform: capitalize;
}

nav ul ul {
  position: absolute;
  top: 90px;
  border-top: 3px solid var(--primary);
  opacity: 0;
  visibility: hidden;
  transition: top 0.3s;
}
nav ul ul ul {
  border-top: none;
}
nav ul li:hover > ul {
  top: 70px;
  opacity: 1;
  visibility: visible;
}
nav ul ul li {
  position: relative;
  margin: 0px;
  float: none;
  display: list-item;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
nav ul ul li a {
  line-height: 40px;
}
nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 150px;
}
.show,
.icon,
input[type=checkbox] {
  display: none;
}
.fa-plus {
  font-size: 15px;
  margin-left: 40px;
}

@media all and (max-width: 968px) {

.topSection {
  display: none;
}

nav .open {
  display: none !important;
}

  nav .ul {
    float: left;
    background-color: #000;
    width: 100%;
    display: block;
  }
  nav .logo {
    margin-left: 10px;
  }
  .show + a,
  ul {
    display: none;
  }

  nav ul li a {
    color: #fff;
  }

  nav ul li a:hover,
  .is-active {
    color: var(--primary) !important;
    box-shadow: none;
  }

  nav ul li {
    width: 100%;
    background: transparent;
  }
  nav ul li a:hover {
    box-shadow: none;
  }
  .show {
    display: block;
    color: #fff;
    font-size: 18px;
    padding: 0 15px;
    line-height: 55px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .icon {
    display: block;
    color: var(--primary);
    position: absolute;
    top: 0;
    right: 40px;
    line-height: 70px;
    cursor: pointer;
    font-size: 30px;
  }
  nav ul ul {
    top: 70px;
    border-top: 0px;
    float: none;
    position: static;
    display: none;
    opacity: 1;
    visibility: visible;
  }
  nav ul ul a {
    padding-left: 40px;
  }

  nav ul ul ul li {
    position: static;
  }
  [id^="btn"]:checked + ul {
    display: block;
  }
  nav ul ul li {
    border-bottom: 0px;
  }
}

