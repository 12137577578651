@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Calibri:300,400,700,900");

* {
  font-family: "Calibri", sans-serif;
  scroll-behavior: smooth;
}

:root {
  --primary: #36af38;
  --secondary: #666;
}

body {
  background: #f8f9fa;
  position: relative;
}

.myContainer {
  @apply container w-full mx-auto lg:px-8 md:px-6 px-5;
}

/* BACK TO TOP */
.scroll-to-top {
  bottom: 13px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 200;
}

.scroll-to-top .icon2 {
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease infinite;
}

@keyframes scrollTop {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(-1px);
  }
}

/*=== General Banner ===*/
.banner {
  height: 50vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5) url("./images/banner.png");
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
}

/*=== Contact Banner ===*/
.contact-banner {
  background: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
}

/* === Button ===*/
.button {
  border-radius: 4px;
  font-size: 18px;
  padding: 10px 5px;
  width: 150px;
  transition: all 0.5s;
}

.button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

/* Hero carousel */
.h-carousel {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  height: 510px;
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
}

/* Testimonial component */
.testimonial {
  @apply bg-white py-4 px-2 text-center border-2 border-secondary rounded-md mx-3 mb-5;
}

.testimonial img {
  @apply h-20 w-20 rounded rounded-full;
}

/* === Team in About page ====*/
.card {
  position: relative;
  height: 370px;
  transition: 0.3s ease-out;
}

.card .img-box {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card .img-box img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  display: block;
  transition: 0.5s;
}

.card .content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding: 20px;
  transition: all 0.5s cubic-bezier(0.48, -0.28, 0.41, 1.4);
  box-sizing: border-box;
  overflow: hidden;
}

.card:hover .content {
  width: 100%;
  height: 50%;
  left: 0;
  bottom: 0;
}

.content p {
  margin: 10px 0 0;
  padding: 1em 1em;
  transform: translateY(2em);
  line-height: 1.3em;
  transition: 0.3s;
  text-align: center;
  font-optical-sizing: 0;
}

.card:hover .content p {
  transform: translateY(0);
  opacity: 1;
}


/* Scrollbar effect */
/* width */
::-webkit-scrollbar {
	width: 20px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px gray; 
	border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: var(--primary); 
	border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: var(--primary); 
  }